import { useState } from "react"

const QuestionAnswer = () => {
  const [data, setData] = useState("");

  return (
     <div>
      <section className="py-10 bg-primary">
      
        <div className="container mx-auto px-6">
          <div className="font-bold text-xl text-white">
      Түгээмэл асуулт
          </div>
        </div>
      </section>
    <div
       class="col-lg-8  Service-about-contain">
                <div>
                    <div class="section-title">
                        <span class="pre-title">Services</span>
                        <h2>MRI Online Reporting
                        </h2>
                    </div>
                    <div class="service-para">
                        <p>
                            Welcome to our state-of-the-art<b> online MRI reporting platform. </b> 
                            We are dedicated to providing healthcare professionals with efficient and accurate MRI
                            report generation and interpretation services. With our team of highly skilled
                            radiologist, we ensure timely and reliable reports to assist in the diagnosis and
                            treatment planning of your patients.

                        </p>
                        <div class="key-feature">
                            <span class="pt-20"><b> Key features:</b> </span>
                            <div class="pt-20">
                                <h5>1. Convenient and Rapid Turnaround: </h5>
                                <p>
                                    Our secure online platform allows you to effortlessly submit MRI scans and receive
                                    comprehensive reports promptly. Say goodbye to the hassle of physical delivery and
                                    experience the convenience of streamlined online reporting.
                                </p>
                            </div>
                            <div class="pt-20">
                                <h5>2. Expert Radiologists at Your Service: </h5>
                                <p>
                                    Our board-certified radiologists are specialized in MRI interpretation. With their
                                    extensive expertise and experience, they excel in analyzing a wide range of MRI
                                    scans and identifying even the most intricate details.
                                </p>
                            </div>

                            <div class="pt-20">
                                <h5>3. Comprehensive and Actionable Reports:</h5>
                                <p>
                                    Our Reports provide thorough findings and interpretations of MRI scans, encompassing
                                    detailed analysis of anatomical structures, identification of lesions or
                                    abnormalities and pertinent clinical correlations. We prioritize clarity,
                                    conciseness and actionable insights to facilitate effective decision-making.
                                </p>
                            </div>
                            <div class="pt-20">
                                <h5>4. Advanced Imaging Techniques: </h5>
                                <p>
                                    Our radiologists are proficient in interpreting various types of MRI studies,
                                    including brain MRI, spine MRI, musculoskeletal MRI, abdominal MRI, cardiac MRI and
                                    more. They leverage cutting-edge imaging techniques to enhance visualization,
                                    ensuring accurate diagnosis.
                                </p>
                            </div>
                            <div class="pt-20">
                                <h5>5. Collaborative Approach and Clear Communication: </h5>
                                <p>
                                    We value collaboration with referring physicians. Our user-friendly interface
                                    facilitates seamless communication, enabling you to discuss cases, seek
                                    clarification and ask question directly with our radiologists for a collaborative
                                    approach to patient care.
                                </p>
                            </div>
                            <div class="pt-20">
                                <h5>6. Data Security and Confidentiality: </h5>
                                <p>
                                    We prioritize patient privacy and adhere to the highest security standards. Our
                                    platform is fully compliant with HIPAA regulations, ensuring the utmost
                                    confidentiality and integrity of all transmitted and stored data.
                                </p>
                            </div>
                        </div>

                        <p class="pt-20">By utilize our online MRI reporting platform, you can enhance your diagnostic
                            capabilities, streamline workflow efficiency and provide optimal care for your patients.
                            Experience the convenience and expertise that our platform offers and join the growing
                            community of healthcare professionals benefitting from our advanced reporting services.
                        </p>

                    </div>

                </div>

     </div>
     </div>
  );
};

export default QuestionAnswer;