import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Form, Input, Button, Select } from "antd";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import { MdOutlineAttachMoney, MdOutlineDescription } from "react-icons/md";
import { instance } from "utils/axios";

const { Option } = Select;

const Training = () => {
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false); // Modal visibility
  const [selectedTraining, setSelectedTraining] = useState(null); // Selected training for registration
  const [form] = Form.useForm(); // Ant Design Form instance

  useEffect(() => {
    setLoading(true);
    instance({
      method: "get",
      url: `/trainings`,
    })
      .then((res) => {
        setTrainings(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  const handleRegisterClick = (training) => {
    setSelectedTraining(training);
    setOpen(true);
  };

  const handleSubmit = (values) => {
    instance({
      method: "post",
      url: `/training/register`, // Adjust endpoint
      data: { ...values, trainingId: selectedTraining.id },
    })
      .then(() => {
        setOpen(false);
        form.resetFields();
        alert("Амжилттай бүртгэгдлээ!");
      })
      .catch((err) => console.error(err));
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <div>
      {/* Header Section */}
      <section className="py-10 bg-primary">
        <div className="container mx-auto px-6">
          <h1 className="font-bold text-2xl text-white">Сургалтын жагсаалт</h1>
        </div>
      </section>

      {/* Training List */}
      <div className="container mx-auto mt-6 px-4 grid grid-cols-12 gap-6">
        {/* Check if data is empty */}
        {loading ? (
          <p>Ачааллаж байна...</p>
        ) : trainings.length === 0 ? (
          <p>Сургалт олдсонгүй</p>
        ) : (
          trainings.map((training, index) => (
            <div
              key={index}
              className="col-span-12 md:col-span-6 lg:col-span-4 bg-white shadow rounded-lg p-4 mb-4"
            >
              <h2 className="text-lg font-semibold">{training.title}</h2>
              <p className="text-sm text-gray-600 flex items-center">
                <AiOutlineClockCircle className="mr-2 text-gray-500" />
                Хугацаа: {training.startDate} - {training.endDate}
              </p>
              <p className="text-sm text-gray-600 flex items-center">
                <FaBuilding className="mr-2 text-gray-500" />
                Байршил: {training.location}
              </p>
              <p className="text-sm text-gray-600 flex items-center">
                <MdOutlineDescription className="mr-2 text-gray-500" />
                Тайлбар: {training.description}
              </p>
              <p className="text-sm text-gray-600 flex items-center">
                <MdOutlineAttachMoney className="mr-2 text-gray-500" />
                Төлбөр: {training.fee}₮
              </p>
              <div className="text-right mt-4">
                <button
                  className="py-2 px-6 bg-primary text-white rounded-xl hover:bg-primary/90"
                  onClick={() => handleRegisterClick(training)}
                >
                  Бүртгүүлэх
                </button>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Registration Modal */}
      <Modal
        title={`Сургалтанд бүртгүүлэх: ${selectedTraining?.title}`}
        visible={open}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="space-y-4"
        >
          <Form.Item
            label="Овог"
            name="lastName"
            rules={[{ required: true, message: "Овогоо оруулна уу!" }]}
          >
            <Input placeholder="Овог" />
          </Form.Item>
          <Form.Item
            label="Нэр"
            name="firstName"
            rules={[{ required: true, message: "Нэрээ оруулна уу!" }]}
          >
            <Input placeholder="Нэр" />
          </Form.Item>
          <Form.Item
            label="Утасны дугаар"
            name="phoneNumber"
            rules={[{ required: true, message: "Утасны дугаараа оруулна уу!" }]}
          >
            <Input placeholder="Утасны дугаар" />
          </Form.Item>
          <Form.Item
            label="И-мэйл"
            name="email"
            rules={[
              { required: true, message: "И-мэйл хаягаа оруулна уу!" },
              { type: "email", message: "И-мэйл хаяг буруу байна!" },
            ]}
          >
            <Input placeholder="И-мэйл" />
          </Form.Item>
          <Form.Item
            label="Мэргэжил"
            name="profession"
            rules={[{ required: true, message: "Мэргэжлээ оруулна уу!" }]}
          >
            <Input.TextArea
              placeholder="Мэргэжлээ дэлгэрэнгүй бичнэ үү"
              rows={4}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-full">
              Бүртгүүлэх
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Training;
