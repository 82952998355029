import Layout from "layout";
import { createBrowserRouter } from "react-router-dom";
import Home from "./Home";
import Employees from "./Employees";
import Jobs from "./Jobs";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import NewsDetail from "./NewsDetail";
import Products from "./Products";
import Services from "./Services";
import ServiceDetail from "./ServiceDetail";
import QuestionAnswer from "./QuestionAnswer";
import ApplicantForm from "./ApplicantForm";
import Training from "./Training";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "doctors",
        element: <Employees />,
      },
      {
        path: "jobs",
        element: <Jobs />,
      },
      {
        path: "training",
        element: <Training />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "about-us",
        element: <AboutUs />,
      },
      {
        path: "products",
        element: <Products />,
      },
      {
        path: "news/:id",
        element: <NewsDetail />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "services/:slug",
        element: <ServiceDetail />,
      },
      {
        path: "applicant/:id",
        element: <ApplicantForm />,
      },
      {
        path: "qa",
        element: <QuestionAnswer />,
      },
      ,
    ],
  },
  {
    path: "about",
    element: <div>About</div>,
  },
]);

export default router