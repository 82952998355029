import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { instance } from "utils/axios";
import { Form, Input, Button, Select, Space, Row, Col,Modal } from "antd";
import { useNavigate } from "react-router-dom";


const { Option } = Select;

const ApplicantForm = () => {
    const navigate = useNavigate();
  const { id } = useParams(); // Route-аас ажлын байрны ID авах
  const [form] = Form.useForm(); // Ant Design form instance
  const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
  const [workDirections, setWorkDirections] = useState([]); // Мэргэшлийн чиглэлүүд
 const specializationOptions = [
   "Толгой хүзүү, мэдрэлийн дүрс оношилгоо",
   "Хэвлийн хөндийн дүрс оношилгоо",
   "Цээжний хөндийн дүрс оношилгоо",
   "Яс үе мөчний дүрс оношилгоо",
   "Эрэгтэй бага аарцгийн дүрс оношилгоо",
   "Эмэгтэй бага аарцгийн дүрс оношилгоо",
   "Хөхний дүрс оношилгоо",
 ];
  // Мэргэшлийн чиглэлийн өгөгдөл татах
  useEffect(() => {
    instance({
      method: "get",
      url: `/job/position/${id}`,
    })
      .then((res) => {
     
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
    instance
      .get("/job/directions")
      .then((res) => setWorkDirections(res.data))
      .catch((err) => console.error(err));
  }, []);
const onFinish = (values) => {
  const payload = {
    ...values,
    positionId: parseInt(id), // Ажлын байрны ID
  };

  setLoading(true);
  instance
    .post("/job/applicant", payload)
    .then(() => {
      Modal.success({
        title: "Амжилттай!",
        content: "Таны өргөдөл амжилттай илгээгдлээ.",
        onOk: () => {form.resetFields()
        navigate("/jobs");
        }, // Reset form after success
      });
    })
    .catch((err) => {
      console.error(err);
      Modal.error({
        title: "Алдаа гарлаа!",
        content: "Өргөдөл илгээхэд алдаа гарлаа. Дахин оролдоно уу.",
      });
    })
    .finally(() => setLoading(false));
};


  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold mb-6 text-center">
          Ажлын байрны өргөдөл илгээх
        </h1>
        {data && (
          <div className="bg-gray-100 rounded-lg p-4 mb-6">
            <p>
              <strong>Гарчиг:</strong> {data.title}
            </p>
            <p>
              <strong>Ажлын Хэлбэр:</strong> {data.workMode}
            </p>
            <p>
              <strong>Байршил:</strong> {data.location}
            </p>

            <p>
              <strong>Шаардлага:</strong> {data.requirements}
            </p>
          </div>
        )}
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="space-y-6"
        >
          {/* Хувийн мэдээлэл */}
          <h2 className="text-lg font-semibold">Хувийн Мэдээлэл</h2>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="firstName"
                label="Овог"
                rules={[{ required: true, message: "Овог оруулна уу!" }]}
              >
                <Input placeholder="Овог" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="lastName"
                label="Нэр"
                rules={[{ required: true, message: "Нэр оруулна уу!" }]}
              >
                <Input placeholder="Нэр" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="phoneNumber"
                label="Утасны дугаар"
                rules={[
                  { required: true, message: "Утасны дугаар оруулна уу!" },
                ]}
              >
                <Input placeholder="Утасны дугаар" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="email"
                label="Имэйл"
                rules={[
                  { required: true, message: "Имэйл оруулна уу!" },
                  { type: "email", message: "Имэйл зөв оруулна уу!" },
                ]}
              >
                <Input placeholder="Имэйл" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="address" label="Хаяг">
            <Input placeholder="Хаяг" />
          </Form.Item>
          <Form.Item
            name="workYear"
            label="Ажилласан жил"
            // rules={[
            //   { required: true, message: "Ажилласан туршлагаа оруулна уу!" },
            // ]} // Optional validation rule
          >
            <Input
              placeholder="Ажилласан жил"
              rows={4} // Adjust the number of rows as needed
            />
          </Form.Item>
          <Form.Item
            name="workExperience"
            label="Ажилласан туршлага"
            // rules={[
            //   { required: true, message: "Ажилласан туршлагаа оруулна уу!" },
            // ]} // Optional validation rule
          >
            <Input.TextArea
              placeholder="Ажилласан туршлагаа дэлгэрэнгүй бичнэ үү"
              rows={4} // Adjust the number of rows as needed
            />
          </Form.Item>

          {/* Боловсрол */}
          <h2 className="text-lg font-semibold">Боловсрол</h2>
          <Form.List name="educations">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Row key={key} gutter={[16, 16]} align="middle">
                    <Col xs={24} sm={6}>
                      <Form.Item
                        {...restField}
                        name={[name, "schoolName"]}
                        fieldKey={[fieldKey, "schoolName"]}
                        rules={[
                          {
                            required: true,
                            message: "Сургуулийн нэр оруулна уу!",
                          },
                        ]}
                      >
                        <Input placeholder="Сургуулийн нэр" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6}>
                      <Form.Item
                        {...restField}
                        name={[name, "degree"]}
                        fieldKey={[fieldKey, "degree"]}
                      >
                        <Input placeholder="Зэрэг" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6}>
                      <Form.Item
                        {...restField}
                        name={[name, "diplomaNumber"]}
                        fieldKey={[fieldKey, "diplomaNumber"]}
                      >
                        <Input placeholder="Дипломын дугаар" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "graduationYear"]}
                        fieldKey={[fieldKey, "graduationYear"]}
                      >
                        <Input type="number" placeholder="Төгссөн он" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={2}>
                      <Button type="text" danger onClick={() => remove(name)}>
                        Устгах
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    + Боловсрол нэмэх
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Мэргэжлийн Үнэмлэх */}
          <h2 className="text-lg font-semibold">Мэргэжлийн Үнэмлэх</h2>
          <Form.List name="certifications">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Row key={key} gutter={[16, 16]} align="middle">
                    <Col xs={24} sm={8}>
                      <Form.Item
                        {...restField}
                        name={[name, "professionalDegree"]}
                        fieldKey={[fieldKey, "professionalDegree"]}
                        rules={[
                          {
                            required: true,
                            message: "Мэргэжлийн зэрэг оруулна уу!",
                          },
                        ]}
                      >
                        <Input placeholder="Мэргэжлийн зэрэг" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Form.Item
                        {...restField}
                        name={[name, "certificateNumber"]}
                        fieldKey={[fieldKey, "certificateNumber"]}
                      >
                        <Input placeholder="Үнэмлэхний дугаар" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6}>
                      <Form.Item
                        {...restField}
                        name={[name, "licenseNumber"]}
                        fieldKey={[fieldKey, "licenseNumber"]}
                      >
                        <Input placeholder="Лицензийн дугаар" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={2}>
                      <Button type="text" danger onClick={() => remove(name)}>
                        Устгах
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    + Үнэмлэх нэмэх
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Мэргэшсэн Чиглэл */}
          <h2 className="text-lg font-semibold">Шинжилгээний төрөл</h2>
          <Form.Item
            name="directions"
            label="Чиглэлүүд"
            rules={[{ required: true, message: "Ядаж нэг чиглэл сонгоно уу!" }]}
          >
            <Select
              mode="multiple"
              placeholder="Чиглэлүүд сонгоно уу"
              allowClear
            >
              {workDirections.map((direction) => (
                <Option key={direction.id} value={direction.name}>
                  {direction.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <h2 className="text-lg font-semibold">Мэргэшсэн чиглэл</h2>
          <Form.Item
            name="specializations"
            label="Мэргэшсэн чиглэлүүд"
            rules={[
              { required: true, message: "Мэргэшсэн чиглэл сонгоно уу!" },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Чиглэлүүд сонгоно уу"
              allowClear
              className="w-full"
            >
              {specializationOptions.map((specialization, index) => (
                <Option key={index} value={specialization}>
                  {specialization}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Илгээх товч */}
          <Form.Item>
            <Button
              className="bg-primary text-white hover:bg-orange-600 active:bg-orange-700 w-full"
              type="primary"
              htmlType="submit"
              size="large"
            >
              Илгээх
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ApplicantForm;
