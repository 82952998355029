import { NavLink } from "react-router-dom"
import { CiMail, CiPhone  } from "react-icons/ci";
import { SlSocialFacebook, SlSocialYoutube } from "react-icons/sl";
import moment from "moment";

const Footer = () => {
  return (
    <footer>
      <div className="bg-black text-white py-10">
        <div className="container mx-auto grid grid-cols-2 md:grid-cols-4 px-6 gap-y-14">
          <div>
            <h1 className="font-medium mb-4">Холбоос</h1>
            <div className="flex flex-col gap-2">
              <NavLink to="/about-us" className="text-sm">
                Бидний тухай
              </NavLink>
              <NavLink to="/contact-us"  className="text-sm">
                Холбоо барих
              </NavLink>
            </div>
          </div>
          <div>
            <h1 className="font-medium mb-4">Хаяг</h1>
            <div className="flex flex-col gap-2">
              Монгол улс, Улаанбаатар хот, Баянзүрх дүүрэг, 25-р хороо,
              <br /> UB Tower оффис 607 тоот
            </div>
          </div>
          <div>
            {/* <h1 className="font-medium mb-4">Үйлчилгээ</h1>
            <div className="flex flex-col gap-2">
              <NavLink className="text-sm">Бидний тухай</NavLink>
              <NavLink className="text-sm">Эмч нар</NavLink>
              <NavLink className="text-sm">Холбоо барих</NavLink>
            </div> */}
          </div>

          <div>
            <h1 className="font-medium mb-4">Холбоо барих</h1>
            <div className="flex flex-col gap-2">
              <a
                href="mailto:example@mail.com"
                className="flex gap-1 items-center"
              >
                <CiMail className="text-2xl" />
                tsahim.durs@gmail.com
              </a>
              <a
                href="tel:example@mail.com"
                className="flex gap-1 items-center"
              >
                <CiPhone className="text-2xl" />
                9403-4075
              </a>
              <div className="flex gap-3">
                <a
                  href="https://www.facebook.com/MonMED.mn"
                  target="_blank"
                  className="w-8 h-8 flex justify-center items-center border"
                >
                  <SlSocialFacebook className="text-xl" />
                </a>
                <a
                  href="https://youtube.com/@DCDR22"
                  target="_blank"
                  className="w-8 h-8 flex justify-center items-center border"
                >
                  <SlSocialYoutube className="text-xl" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4 bg-slate-100">
        <div className="container mx-auto text-sm leading-none px-6">
          Copyright © DCDR {moment().format("YYYY")}. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer