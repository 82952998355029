import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { AiOutlineEnvironment, AiOutlineClockCircle } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import { MdWorkOutline, MdAlignVerticalBottom } from "react-icons/md";
import { instance } from "utils/axios";

const Jobs = () => {
  const navigate = useNavigate(); // Initialize navigate
  const [data, setData] = useState([]);
  const [workDirections, setWorkDirection] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    instance({
      method: "get",
      url: `/job/directions`,
    })
      .then((res) => {
        console.log(res.data);
        setWorkDirection(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));

    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/job/position`,
    })
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const [filters, setFilters] = useState({
    search: "",
    workMode: "",
  });

  const filteredData = useMemo(() => {
    return data.filter((job) => {
      const matchesSearch =
        filters.search === "" ||
        job.title.toLowerCase().includes(filters.search.toLowerCase());
      const matchesWorkMode =
        filters.workMode === "" || job.workMode === filters.workMode;

      return matchesSearch && matchesWorkMode;
    });
  }, [data, filters]);

  const handleApplyClick = (jobId) => {
    navigate(`/applicant/${jobId}`); // Navigate to applicant form page with jobId
  };

  return (
    <div>
      {/* Header Section */}
      <section className="py-10 bg-primary">
        <div className="container mx-auto px-6">
          <div className="font-bold text-xl text-white">Ажлын байр</div>
        </div>
      </section>

      {/* Filters and Results */}
      <div className="container mx-auto grid grid-cols-12 gap-6 mt-6 px-4">
        {/* Filters Column */}
        <div className="col-span-12 md:col-span-3 bg-white shadow rounded-lg p-4">
          <h2 className="text-lg font-semibold mb-4">Шүүлтүүр</h2>

          {/* Search Field */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Хайх</label>
            <input
              type="text"
              placeholder="Search..."
              value={filters.search}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, search: e.target.value }))
              }
              className="w-full p-2 border rounded-md text-gray-900 border-gray-300 focus:ring focus:ring-yellow-500 focus:border-yellow-500"
            />
          </div>

          {/* Work Mode Dropdown */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Хэлбэр</label>
            <select
              value={filters.workMode}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, workMode: e.target.value }))
              }
              className="w-full p-2 border rounded-md text-gray-900 border-gray-300 focus:ring focus:ring-yellow-500 focus:border-yellow-500"
            >
              <option value="">Бүгд</option>
              <option value=">Бүтэн цаг">Бүтэн цаг</option>
              <option value="Хагас цаг"> Хагас цаг</option>
              <option value="Шөнийн ээлж">Шөнийн ээлж</option>
              <option value="Яаралтай">Яаралтай</option>
            </select>
          </div>
        </div>

        {/* Job Listings Column */}
        <div className="col-span-12 md:col-span-9">
          {filteredData.length === 0 ? (
            <p>Олдсонгүй</p>
          ) : (
            filteredData.map((job, index) => (
              <div
                key={index}
                className="bg-white shadow rounded-lg p-4 mb-4 flex justify-between items-center"
              >
                <div>
                  <h3 className="text-lg font-semibold">{job.title}</h3>
                  <p className="text-sm text-gray-600 flex items-center">
                    <AiOutlineEnvironment className="mr-2 text-gray-500" />
                    {job.location}
                  </p>
                  <p className="text-sm text-gray-600 flex items-center">
                    <AiOutlineClockCircle className="mr-2 text-gray-500" />
                    {job.requirements}
                  </p>
                  <p className="text-sm text-gray-600 flex items-center">
                    <MdWorkOutline className="mr-2 text-gray-500" />
                    {job.workMode}
                  </p>
               
                  {job.description?
                  <p className="text-sm text-gray-600 flex items-center">
                    <FaBuilding className="mr-2 text-gray-500" />
                    {job.description}
                  </p>:<></>
                  }
                </div>
                <div className="text-right">
                  <button
                    className="py-2 px-6 bg-primary text-white rounded-xl hover:bg-primary/90"
                    onClick={() => handleApplyClick(job.id)}
                  >
                    Илгээх
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Jobs;
