import AboutUs from "./components/AboutUs"
import Carousel from "./components/Carousel"
import JoinUs from "./components/JoinUs"
import Map from "./components/Map"
import News from "./components/News"
import Partners from "./components/Partners"
import VideoUs from "./components/VideoUs";
import Services from "./components/Services"
import Operation from "./components/Operation";
import FAQ from "./components/FAQ"
import Testimonials from "./components/Testimonials";

const Home = () => {
  return (
    <div>
      <Carousel></Carousel>
      <AboutUs></AboutUs>
      <Operation></Operation>
      <Services></Services>
      <VideoUs></VideoUs>
      {/* <JoinUs>

      </JoinUs> */}
      {/* <Products></Products> */}
      <News></News>
      {/* <Testimonials></Testimonials> */}
      <FAQ></FAQ>
      {/* <Partners></Partners> */}
      <Map></Map>
    </div>
  );
}

export default Home