import { Splide, SplideSlide } from '@splidejs/react-splide';
import slide1 from "../../../assets/image/slide4.png";
import slide2 from "../../../assets/image/slide5.png";
import { useNavigate } from "react-router-dom";
const Carousel = () => {
  const navigate = useNavigate();
  return (
    <section>
      <Splide
        ariaLabel="carousel"
        options={{
          type: "loop",
          autoplay: "pause",
          perPage: 1,
          height: "50vh",
          width: "100vw",
        }}
      >
        <SplideSlide>
          <div
            className="h-full bg-no-repeat bg-right flex items-center relative"
            style={{
              backgroundImage: `url(${slide1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {/* Tailwind Gradient */}
            <div className="absolute inset-0 w-3/5 left-0 bg-black-gradient"></div>

            <div className="container mx-auto z-10 px-6">
              <div className="text-primary font-bold text-3xl">
                DCDR Дүрс оношилгооны ЦАХИМ ТӨВ
              </div>
              <div className="text-white font-medium mt-3 mb-10">
                Бидэнтэй нэгдэх
              </div>
              <button
                className="bg-primary py-2 px-10 rounded text-white font-medium"
                onClick={() => {
                  navigate("/jobs");
                }}
              >
                Холбоо барих
              </button>
            </div>
          </div>
        </SplideSlide>

        <SplideSlide>
          <div
            className="h-full bg-no-repeat bg-right flex items-center relative"
            style={{
              backgroundImage: `url(${slide2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute inset-0 w-3/5 left-0 bg-black-gradient"></div>

            <div className="container mx-auto z-10 px-6">
              <div className="text-primary font-bold text-3xl">
                DCDR Дүрс оношилгооны ЦАХИМ ТӨВ
              </div>
              <div className="text-white font-medium mt-3 mb-10">
                Join Our Team
              </div>
              <button
                className="bg-primary py-2 px-10 rounded text-white font-medium"
                onClick={() => {
                  navigate("/jobs");
                }}
              >
                Холбоо барих
              </button>
            </div>
          </div>
        </SplideSlide>
      </Splide>
      <div className="grid grid-cols-2 md:grid-cols-4 bg-primary py-10 gap-y-4">
        <div className="flex flex-col justify-center items-center gap-3 border-r border-white/80">
          <div className="font-bold text-4xl text-white">24</div>
          <div className="text-white">Нийт эмчийн тоо</div>
        </div>
        <div className="flex flex-col justify-center items-center gap-3 border-r border-white/80">
          <div className="font-bold text-4xl text-white">10</div>
          <div className="text-white">Хамтран ажиллагч эмнэлэгийн тоо</div>
        </div>
        {/* <div className="flex flex-col justify-center items-center gap-3 border-r border-white/80">
          <div className="font-bold text-4xl text-white">450+</div>
          <div className="text-white">Үйлчлүүлэгчийн тоо</div>
        </div> */}
        <div className="flex flex-col justify-center items-center gap-3 border-r border-white/80">
          <div className="font-bold text-4xl text-white">2,000+</div>
          <div className="text-white">Нийт тохиолдлын тоо /сард/</div>
        </div>
        <div className="flex flex-col justify-center items-center gap-3 border-r border-white/80">
          <div className="font-bold text-4xl text-white">26</div>
          <div className="text-white">Мэргэжилтний тоо</div>
        </div>
      </div>
    </section>
  );
}

export default Carousel